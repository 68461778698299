.bg-image {
  left: 0;
  height: auto;
  max-height: 100%;
  min-height: 100%;
  min-width: 320px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
